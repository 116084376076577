export function getLangFromExtension (extension:string){
    if(extension.indexOf(".") > -1)  extension = extension.split('.').pop()??"";
    switch(extension){
        case "vb":
            return "vb";
        case "cs":
        case "csx":
            return "csharp";
        case "fs":
        case "fsx":
            return "fsharp";
        case "css":
            return "css";
        case "less":
            return "less";
        case "js":
            return "javascript";
        case "jsx":
            return "javascript";
        case "ts":
            return "typescript";
        case "rs":
            return "rust";
        case "razor":
            return "razor";
        case "config":
        case "settings":
        case "resx":
        case "myapp":
        case "xml":
        case "lic":
        case "vbproj":
        case "csproj":
        case "fsproj":
        case "targets":
        case "wixproj":
        case "wxs":
        case "proj":
        case "props":
        case "xslt":
            return "xml";
        case "manifest":
        case "json":
            return "json";
        case "ini":
        case "sln":
            return "ini";
        case "sql":
            return "sql";
        case "markdown":
            return "markdown";
        case "vue":
        case "tpl":
            return "html";
        default:
            return 'sql';
    }
}

/**
 * Load CDN libraries types into monaco control
 */
export async function loadLibraryTypes(pMonacoControl: any, pOptions: {
    includeFirstParty: boolean,
    cdnLibs?: string[]
}) {
    if (!pOptions?.includeFirstParty) { return []; }
    const { getOrCreateDataObject} = await import('o365.vue.ts');

    const orPart = pOptions.cdnLibs 
        ? 'OR ID IN (' + pOptions.cdnLibs.map(x => `'${x}'`).join(',') + ')'
        : '';
    const dsSystemLibsUrls = getOrCreateDataObject({
        id: 'dsSystemLibsUrls',
        viewName: 'stbv_O365_LibsUrls',
        fields: [
            { name: 'ID'},
            { name: 'ModuleName'},
            { name: 'DeclarationPath'},
            { name: 'Path'},
            { name: 'LibraryType'},
        ],
        whereClause: `[DeclarationPath] IS NOT NULL AND ([IsO365Library] = 1 ${orPart})` ,
        // whereClause: pOptions.includeFirstParty 
        //     ? '[DeclarationPath] IS NOT NULL' 
        //     : '[DeclarationPath] IS NOT NULL AND [IsO365Library] = 0'
    });

    const { monaco } = await import('o365.controls.MonacoControl.ts');

    let vueProxy: any = null;
    const executeAfterVueSetup = async (pFunction: () => void) => {
        if (pMonacoControl.vueSetupPromise) {
            await pMonacoControl.vueSetupPromise;
        }
        if (vueProxy == null) {
            vueProxy = await monaco.languages.vue?.worker?.getProxy();
        }
        pFunction();
    };

    await dsSystemLibsUrls.load();
    const cdnBaseUrl = (document.querySelector<HTMLMetaElement>('meta[name="o365-cdn-base-url"]'))?.content;
    const addedLibs = new Set<string>();
    const promises = dsSystemLibsUrls.data.map(lib => {
        return fetch(`${cdnBaseUrl}/${lib.Path}${lib.DeclarationPath}`).then(result => {
            if (result.status !== 200) { throw new Error('Missing dts will be ignored') }
            return result.text();
        }).then(content => {
            const moduleName = lib.ModuleName??lib.ID;
            // if (moduleName === 'vue') { return; }
            addedLibs.add(moduleName);
            const dtsContent = lib.LibraryType == 'Module'
                ? `declare module '${moduleName}' { ${content} }`
                : content;
            monaco.languages.typescript.javascriptDefaults.addExtraLib(
                dtsContent,
                `file:///node_modules/@types/${moduleName}/index.d.ts`
            );
            monaco.languages.typescript.typescriptDefaults.addExtraLib(
                dtsContent,
                `file:///node_modules/@types/${moduleName}/index.d.ts`
            );
            executeAfterVueSetup(() => {
                if (moduleName === 'vue') { return; }
                vueProxy.updateOmega365ExtraLib(`file:///node_modules/@types/${moduleName}/index.d.ts`, dtsContent);
            });
        }).catch(_ex => {
            // filter out errors
        });
    });

    promises.push(import('o365.modules.configs.ts').then((m) => {
        if (![96463].includes(m.userSession.personId??0)) { return; }
        return fetch('/nt/cdn/apryse/10.10.1/types.d.ts').then(async (result) => {
            if (result.status !== 200) { throw new Error('Missing dts will be ignored') }
            const dtsContent = await result.text();
            const moduleName = 'apryse';
            addedLibs.add(moduleName);
            monaco.languages.typescript.javascriptDefaults.addExtraLib(
                dtsContent,
                `file:///node_modules/@types/${moduleName}/index.d.ts`
            );
            monaco.languages.typescript.typescriptDefaults.addExtraLib(
                dtsContent,
                `file:///node_modules/@types/${moduleName}/index.d.ts`
            );
            executeAfterVueSetup(() => {
                vueProxy.updateOmega365ExtraLib(`file:///node_modules/@types/${moduleName}/index.d.ts`, dtsContent);
            });
        })
    }));
    await Promise.all(promises);
    return Array.from(addedLibs);
} 